import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	firstLine: {
		color: theme.workwaveBlue,
		fontSize: '2.25rem',
    fontWeight: 700,
	},
  secondLine: {
		fontSize: '3.75rem',
    fontWeight: 700,
    color: '#2a7abc',
    textTransform: 'uppercase'
  },
  paragraph: {
    marginRight: '7em',
    lineHeight: '32px', 
    marginTop: '2rem',
    [theme.breakpoints.down('lg')]: {
			marginRight: '4em',
		},
    [theme.breakpoints.down('md')]: {
			marginRight: '0',
		},
  }
}));

export const Overview = ({ overview }) => {
  const classes = useStyles();
	return (
		<div style={{ padding: '2rem 0' }}>
			<PortableText
				content={overview}
				serializers={{
					h2: ({ children }) => (
						<Typography
							variant='h2'
							color='primary'
							className={classes.firstLine}
              style={{ lineHeight: '1.0' }}>
							{children}
						</Typography>
					),
					em: ({ children }) => (
						<span className={classes.secondLine}>{children}</span>
					),
					normal: ({ children }) => (
						<Typography
							variant='body1'
							className={classes.paragraph}>
							{children}
						</Typography>
					),
				}}
			/>
		</div>
	);
};
