import React from 'react';
// import ReactPlayer from 'react-player/lazy';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const Video = loadable(() => import('../../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	thumbnailImage: {
		marginTop: '-15rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-13rem',
		},
	},
	'@keyframes pulseBorder': {
		'0%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
			opacity: '1',
		},
		'100%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
			opacity: '0',
		},
	},
	playButton: {
		height: '100px',
		width: '100px',
		background: '#fff',
		color: '#002d5c',
		fontSize: '1.5rem',
		borderRadius: '50%',
		position: 'absolute',
		// left: '100%',
		// marginTop: '-20px',
		// marginLeft: '-50px',
		// top: '44%',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		cursor: 'pointer',
		zIndex: 5,

		'&::before': {
			content: `''`,
			position: 'absolute',
			zIndex: '-1',
			left: '50%',
			top: '50%',
			transform: 'translateX(-50%) translateY(-50%)',
			display: 'block',
			width: '100px',
			height: '100px',
			background: '#fff',
			borderRadius: '50%',
		},

		'&:hover': {
			'& $playButtonEffect': { transform: 'scale(1.1)' },
			'&::before': {
				animation: '$pulseBorder 1500ms ease-out infinite',
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	playButtonEffect: {
		background: 'rgba(0, 45, 92,0.95)',
		padding: '130px',
		position: 'absolute',
		// left: '100%',
		// marginTop: '-100px',
		// marginLeft: '-128px',
		// top: '44%',
		borderRadius: '50%',
		mixBlendMode: 'multiply',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		zIndex: 5,
		transition: 'all 1s ease-in-out',

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '32.95%',
		minHeight: '100%',
		minWidth: '59%',
		border: 'none',
		marginTop: '-8rem',
		zIndex: 10,
		borderRadius: '8px',
		overflow: 'hidden',
		boxShadow:
			'0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
		[theme.breakpoints.down('md')]: {
			marginTop: '-11rem',
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: '45%',
			minHeight: '100%',
			minWidth: '80%',
			marginTop: '-8rem',
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: '56.25%',
			minHeight: '100%',
			minWidth: '100%',
			marginTop: '-3rem',
		},
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
}));

export const HeroVideo = ({ thumbnailImage, wistiaLink, setHeroModalOpen }) => {
	const classes = useStyles();
	// const md = useMediaQuery('(max-width: 960px)');

	return (
		// <Grid container justifyContent='center' className={classes.thumbnailImage}>
		<Grid
			container
			direction='row'
			justifyContent='center'
			alignItems='center'
			item
			xs>
			{/* {md ? ( */}
			<div className={classes.playerWrapper}>
				{/* <ReactPlayer
					url={wistiaLink}
					className={classes.reactPlayer}
					height='100%'
					width='100%'
					controls={true}
				/> */}
				<Video url={wistiaLink} height='100%' width='100%' controls={true} />
			</div>
			{/* ) : (
					<>
						<GatsbyImage image={thumbnailImage.asset?.gatsbyImageData} />
						<div className={classes.playButtonEffect} />
						<div
							className={classes.playButton}
							onClick={() => setHeroModalOpen(true)}>
							<FontAwesomeIcon icon={['fas', 'play']} size='lg' />
						</div>
					</>
				)} */}
		</Grid>
		// </Grid>
	);
};
